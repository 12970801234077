<template>
  <div class="home">
    <section class="hero is-medium is-primary">
      <div class="hero-body has-text-centered">
        <img src="../assets/logo-gold-large.png" class="jumbo-logo">
        <div class="selector">
          <p class="heading has-text-white">Explore firms for your internship</p>
          <br>
          <div class="columns is-centered">
            <div class="column is-9-desktop">
              <div class="cat-selector columns is-multiline is-centered is-vcentered is-mobile">
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/all">All Firms →</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/private-firms">Private Firms →</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/ngo">NGOs →</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/gov">Government Agencies →</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-content has-text-centered">
      <h1 class="title">Featured firms</h1>
      <FirmLogoWall></FirmLogoWall>
    </section>
    <section class="home-content has-text-centered">
      <sponsors-list></sponsors-list>
    </section>
  </div>
</template>

<script>
import SponsorsList from "../components/SponsorsList";
import FirmLogoWall from "../components/FirmLogoWall";
export default {
  name: 'Home',
  components: {FirmLogoWall, SponsorsList},
  title: 'Adhika: Ateneo Law School Career Fair 2022'
}
</script>

<style scoped>
.jumbo-logo {
  max-height: 240px;
}

.selector {
  padding-top: 54px;
}

.cat-selector {
  border-radius: 2px;
  background: #162033;
}

.home-content {
  padding: 4.2%;
}
</style>
